@mixin maas-table-sortable {
  %heading-icon {
    @include vf-icon-chevron;
    @include vf-icon-size(0.875rem);
    $vertical-offset: 1px;

    background: {
      repeat: no-repeat;
      size: 100%;
    }

    content: "";
    display: inline-block;
    margin-left: $sp-x-small;
    vertical-align: calc(
      #{$vertical-offset} + #{0.5 * $cap-height} - #{0.5 * $default-icon-size}
    );
  }

  .p-table--sortable [role="columnheader"] {
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &.is-sorted.sort-asc::after {
      @extend %heading-icon;
    }

    &.is-sorted.sort-desc::after {
      @extend %heading-icon;

      transform: rotate(180deg);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
