@mixin maas-network-discovery-config {
  .l-grid__network-discovery-config {
    @extend %full-span-grid;
    grid:
      [row1-start] "form-fields help-text" min-content [row1-end]
      / 1fr 1fr;

    .form-fields {
      grid-area: form-fields;
    }

    .help-text {
      grid-area: help-text;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      grid:
        [row1-start] "form-fields" min-content [row1-end]
        [row2-start] "help-text" max-content [row2-end]
        / 1fr;
    }
  }
}
