@mixin maas-table-images {
  .p-table--images {
    .p-table__row {
      .p-table__cell {
        &:nth-child(1) {
          width: 20%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 35%;
        }

        &:nth-child(5) {
          width: 15%;
        }
      }
    }
  }
}
