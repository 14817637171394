$vanilla-2-icon-button-side-padding: $sph-inner--small * 1.5;
@mixin maas-p-buttons {
  // disable override in vanilla that make buttons vertically less padded; this causes issues with alignment with other elements
  table button,
  table [class^="p-button"] {
    padding-bottom: calc(#{$spv-nudge} - 1px);
    padding-top: calc(#{$spv-nudge} - 1px);
  }

  .p-button--base.is-small {
    padding: $sp-unit;
    margin: 0;
  }

  %icon-button-padding {
    padding: calc(#{$spv-nudge} - 1px) $sph-inner--small;
  }

  .p-button--icon {
    margin: 0 !important;
    padding: 0 #{$sph-inner--small / 2} !important;
  }

  .p-button--close {
    align-self: flex-start; // stay vertically at top
    border: 0;
    float: right; // in case it is not in a flex container, align it to the right
    margin: 0 0 auto auto;
    width: auto;

    @extend %icon-button-padding;

    [class^="p-icon"] {
      margin-right: 0;
    }
  }

  .p-button--link {
    @extend %vf-button-base;
    background-color: inherit;
    border: 0;
    color: $color-link;
    line-height: 1.25rem;
    margin-bottom: 0;
    padding: 0;
    pointer-events: all;
    text-align: left;

    &:hover {
      background-color: inherit;
      text-decoration: underline;
    }

    &:visited {
      color: $color-link;
    }

    // Override inherited button style from Vanilla
    &:active {
      background-color: inherit !important;
    }
  }

  [class*="p-button"] [class^="p-icon"],
  button [class^="p-icon"] {
    margin-right: 0;
  }

  *[class*="p-button"] {
    .p-table-expanding__panel & {
      margin-bottom: 2 * $spv-nudge-compensation;

      &:not(.p-button--close) {
        padding: calc(#{$spv-nudge} - 1px) $sph-inner;
      }
    }

    &.is-small {
      padding: calc(#{$spv-nudge} - 1px) $sph-inner;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

@media only screen and (max-width: 460px) {
  //XXX width: 100% is breaking many things, for example the check power button in the header causes horizontal scroll. Removing pending further discussion.
  button,
  [type="submit"],
  .p-button,
  .p-button--neutral,
  .p-button--brand,
  .p-button--positive,
  .p-button--negative,
  .p-button--base {
    width: auto;
  }
}
