@mixin maas-b-tables {
  %ellipse-text {
    @media (min-width: $breakpoint-small) {
      text-overflow: ellipsis;
      overflow-x: hidden;
      overflow-y: visible;
      white-space: nowrap;
    }
  }

  table {
    overflow-x: visible;
    input[type="radio"],
    input[type="checkbox"] {
      float: none;
    }

    form {
      input[type="radio"],
      input[type="checkbox"] {
        float: left;
      }
    }

    thead {
      th {
        @extend %smallest-text;
        margin-bottom: 0;
        padding-bottom: map-get($line-heights, default-text) -
          $line-height--smallest - $nudge--smallest;
        padding-top: map-get($nudges, nudge--smallest);
        text-transform: uppercase;
      }
    }

    th,
    td {
      @extend %ellipse-text;
      display: table-cell !important;
      flex-basis: auto !important;
      flex-grow: 0;
      vertical-align: top;

      &:first-of-type {
        padding-left: $sph-inner--small;
      }

      &:not(:last-child) {
        padding-right: $sph-inner--small;
      }
    }

    &.p-table--no-truncation {
      th,
      td {
        overflow: visible;
        text-overflow: unset;
        white-space: unset;
      }
    }

    p {
      @extend %ellipse-text;
    }

    label {
      &:empty {
        display: inline;
        margin-right: $sph-inner;
        padding-right: $sph-inner;
      }
    }

    input[type="checkbox"] {
      &:focus + label {
        outline: 0;

        &::before {
          outline: 2px solid $color-focus;
          transition: 0s;
        }
      }

      + label {
        &::before {
          top: 0.1rem;
        }

        &::after {
          top: 0.35rem;
        }
      }
    }
  }

  tr {
    &.is-active {
      background-color: $color-x-light;
    }

    &.is-suppressed {
      > td:nth-child(2),
      > td:nth-child(3),
      > td:nth-child(4),
      > td:nth-child(5),
      > td:nth-child(6) {
        opacity: 0.5;
      }
    }

    thead & {
      border-bottom-color: $color-mid-light;
    }

    tbody & {
      &:not(:first-child) {
        border-top-color: $color-light-new;
      }
    }
  }

  tr.ng-hide + tr {
    border: 0;
  }

  .p-table--action-cell {
    overflow: visible;
  }

  .numa-tooltip {
    padding-left: $sph-inner;

    &.show-warning::after {
      @include vf-icon-size(map-get($icon-sizes, default));
      @include vf-icon-warning($color-warning);
      content: "";
      left: 0;
      position: absolute;
      top: #{$sp-unit / 2};
    }
  }

  .p-table--grouped {
    tr:not(:first-of-type) {
      border-top: 0;
    }

    td {
      border-top: 1px solid $color-light-new;

      &.no-border {
        border-color: transparent;
      }
    }
  }

  .p-domain-name {
    display: inline-block;

    .p-domain-name__host {
      font-weight: 400;
    }

    .p-domain-name__tld {
      @extend %small-text;
      margin-bottom: map-get($line-heights, default-text) -
        map-get($line-heights, small) - map-get($nudges, nudge--small);
    }
  }
}
