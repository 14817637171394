@mixin maas-table-devices {
  .p-table--devices {
    .p-table__row {
      .p-table__cell {
        &:nth-child(1) {
          width: 33%;
        }

        &:nth-child(2) {
          width: 17%;
        }

        &:nth-child(3) {
          width: 15%;

          @media (max-width: 1000px) {
            display: none !important;
          }
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 15%;
        }
      }
    }
  }
}
