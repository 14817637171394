@mixin maas-table-controllers {
  .p-table--controllers {
    .p-table__row {
      .p-table__cell {
        &:nth-child(1) {
          width: 20%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 10%;
        }

        &:nth-child(5) {
          width: 15%;
        }

        &:nth-child(6) {
          width: 15%;
        }

        &:nth-child(7) {
          width: 15%;
        }
      }
    }
  }

  .p-table--controller-interfaces {
    .p-table--is-device {
      th,
      td {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 20%;
        }
      }
    }

    .p-table--is-not-device {
      th,
      td {
        &:nth-child(1) {
          width: 16%;
        }
        &:nth-child(2) {
          width: 5%;
        }
        &:nth-child(3) {
          width: 14%;
        }
        &:nth-child(4) {
          width: 14%;
        }
        &:nth-child(5) {
          width: 11%;
        }
        &:nth-child(6) {
          width: 12%;
        }
        &:nth-child(7) {
          width: 11%;
        }
        &:nth-child(8) {
          width: 12%;
        }
        &:nth-child(9) {
          width: 5%;
        }

        @media only screen and (max-width: $breakpoint-x-large) {
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            width: 6%;
          }
          &:nth-child(3) {
            width: 16%;
          }
          &:nth-child(4) {
            width: 15%;
          }
          &:nth-child(5) {
            width: 12%;
          }
          &:nth-child(6) {
            width: 13%;
          }
          &:nth-child(7) {
            width: 12%;
          }
          &:nth-child(8) {
            display: none !important;
            width: 0;
          }
          &:nth-child(9) {
            width: 6%;
          }
        }

        @media only screen and (max-width: $breakpoint-large) {
          &:nth-child(1) {
            width: 26%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            display: none !important;
            width: 0;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 17%;
          }
          &:nth-child(6) {
            width: 17%;
          }
          &:nth-child(7) {
            display: none !important;
            width: 0;
          }
          &:nth-child(9) {
            width: 10%;
          }
        }

        @media only screen and (max-width: $breakpoint-medium) {
          &:nth-child(1) {
            width: 45%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(4) {
            width: 45%;
          }
          &:nth-child(5) {
            display: none !important;
            width: 0;
          }
          &:nth-child(6) {
            display: none !important;
            width: 0;
          }
          &:nth-child(9) {
            display: none !important;
            width: 0;
          }
        }
      }

      th:nth-child(4) {
        padding-left: $sph-inner--large;
      }

      &.indented-border {
        border-color: transparent;
        position: relative;

        &::after {
          background-color: $color-light-new;
          content: "";
          height: 1px;
          left: #{2 * map-get($icon-sizes, default) + $sph-inner--small};
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .p-table-interface {
    th,
    td {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 5%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 12%;
      }
      &:nth-child(5) {
        width: 12%;
      }
      &:nth-child(6) {
        width: 12%;
      }
      &:nth-child(7) {
        width: 12%;
      }
      &:nth-child(8) {
        width: 12%;
      }
    }

    th:nth-child(4) {
      padding-left: $sph-inner--large;
    }

    .p-table--is-device {
      th,
      td {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 20%;
        }
      }

      th:nth-child(2) {
        padding-left: $sph-inner--large;
      }

      th:nth-child(4) {
        padding-left: $sph-inner--small;
      }
    }
  }

  .p-table--controller-vlans {
    .p-table__row {
      @media (min-width: $breakpoint-small) {
        th,
        td {
          &:nth-child(1) {
            width: 15%;
          }
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3) {
            width: 10%;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 20%;
          }
          &:nth-child(6) {
            width: 20%;
          }
        }
      }
    }
  }
}
