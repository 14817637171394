@mixin maas-table-static-routes {
  .p-table--static-routes {
    th,
    td {
      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(2) {
        width: 32%;
      }

      &:nth-child(3) {
        width: 16%;
      }

      &:nth-child(4) {
        width: 27%;
      }

      &:nth-child(5) {
        width: 100%;
      }
    }
  }
}
