//does not work with colors containing alpha
@function encodecolor($string) {
  @if type-of($string) == "color" {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: "%23" + $string;
  @return $string;
}

.u-position-relative {
  position: relative;
}

.u-width--auto {
  width: auto !important;
}

.u-flex--no-wrap {
  display: flex;
  flex-wrap: wrap;
}

.u-flex--space-between {
  display: flex;
  justify-content: space-between;
}

$table-h-indent: $sph-inner--small;

.u-space-between {
  display: flex;
  justify-content: space-between;
}

.u-space-vertically {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.u-flex-bottom {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.u-disable {
  opacity: 0.5;
  pointer-events: none !important;
  user-select: none;
}

.u-mirror--y {
  transform: rotate(180deg);
}

.u-rotate {
  transform: rotate(180deg);
}

.u-fill-container {
  height: 100%;
  width: 100%;
}
