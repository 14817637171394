@import "tables/base_tables";
@import "tables/breakpoint-widths";
@import "tables/patterns_double-row";
@import "tables/patterns_table-controllers";
@import "tables/patterns_table-devices";
@import "tables/patterns_table-dns";
@import "tables/patterns_table-expanding";
@import "tables/patters_table-fabrics";
@import "tables/patterns_table-images";
@import "tables/patterns_table-ipranges";
@import "tables/patterns_table-network-discovery";
@import "tables/patterns_table-network-summary";
@import "tables/patterns_table-ssh-keys";
@import "tables/patterns_table-sortable";
@import "tables/patterns_table-static-routes";
@import "tables/patterns_table-storage";
@import "tables/patterns_table-subnets";
@import "tables/patterns_table-testing";
@import "tables/patterns_table-zones";
@import "tables/patterns_table-dhcp-snippets";

@include maas-b-tables;
@include maas-double-row;
@include maas-table-controllers;
@include maas-table-dns;
@include maas-table-devices;
@include maas-table-expanding;
@include maas-table-fabrics;
@include maas-table-images;
@include maas-table-ipranges;
@include maas-table-network-discovery;
@include maas-table-network-summary;
@include maas-table-sortable;
@include maas-table-ssh-keys;
@include maas-table-static-routes;
@include maas-table-storage;
@include maas-table-subnets;
@include maas-table-testing;
@include maas-table-zones;
@include maas-table-dhcp-snippets;

// Global MAAS table classes
.p-muted-text {
  @extend %small-text;
  color: $color-mid-dark;
  margin: 0;
  padding: 0;
}

.p-link--muted {
  &:visited {
    color: $color-mid-dark;
  }

  &:hover {
    color: $color-link;
  }
}

.p-table__row--muted {
  background-color: $color-light;
}
