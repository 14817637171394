@mixin maas-action-button {
  .p-action-button {
    position: relative;

    &::before {
      background-size: map-get($icon-sizes, default);
      content: "";
      height: map-get($icon-sizes, default);
      left: $sph-inner;
      position: absolute;
      top: calc(#{$spv-inner--x-small + $spv-nudge} - 1px);
      width: map-get($icon-sizes, default);
    }

    &.is-indeterminate,
    &.is-done {
      padding-left: $sph-inner--large + map-get($icon-sizes, default);
    }

    &.is-indeterminate {
      &::before {
        @include vf-icon-spinner($color-mid-dark);
        animation: spin map-get($animation-duration, sleepy) infinite linear;
      }

      &.p-button--positive::before,
      &.p-button--negative::before {
        @include vf-icon-spinner($color-x-light);
      }
    }

    &.is-done {
      &::before {
        @include vf-icon-success($color-positive);
        animation: none;
      }

      &.p-button--positive::before {
        @include maas-icon-success($color-x-light, $color-positive);
      }

      &.p-button--negative::before {
        @include maas-icon-success($color-x-light, $color-negative);
      }
    }
  }
}
