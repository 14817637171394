@mixin maas-tag-input {
  tags-input {
    display: block;

    .autocomplete {
      margin-top: -1px;
      position: absolute;
      padding: 5px 0;
      z-index: 999;
      width: 100%;
      background-color: #fff;
      border-radius: 0 0 3px 3px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      max-height: 300px;
      transition: max-height 0.3s ease-in;
      overflow-y: scroll;
      overflow-x: visible;

      &.no-suggestion {
        overflow-y: visible;
      }

      .suggestion-list {
        margin: 0;
        clear: both;
        min-width: 160px;
        width: 100%;
        box-sizing: border-box;
        list-style: none;
        padding-left: 0;

        .suggestion-item {
          float: left;
          margin: 0;
          padding: 5px 10px;
          border-top: 1px solid #d2d2d2;
          width: 100%;

          &:first-child {
            border-top: 0;
          }

          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }

  .autocomplete.no-suggestion .suggestion-list .suggestion-item {
    color: $color-mid-dark;

    &:hover {
      background-color: $color-x-light;
    }
  }

  #tags {
    display: block;
  }

  .tags--inline {
    .host {
      position: relative;
      margin-bottom: 5px;
      height: 100%;
    }

    .tags {
      border: 1px solid #999;
      border-radius: 2px;

      -moz-appearance: textfield;
      -webkit-appearance: textfield;
      padding: 1px;
      overflow: hidden;
      word-wrap: break-word;
      cursor: text;
      background-color: #fff;
      height: 100%;

      &:focus,
      &.focused {
        border-color: #888;
        outline: none;
      }

      .tag-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .tag-item {
          line-height: 24px;
          margin: 4px;
        }
      }

      .input {
        border: 0;
        outline: 0;
        margin: 2px;
        padding: 0 0 0 5px;
        height: 30px;
        box-shadow: none;

        &:placeholder {
          color: transparent;
        }
      }
    }
  }

  .tags {
    width: 100%;

    .tag-list {
      width: 100%;
      margin: 0;
      overflow: hidden;

      .tag-item {
        float: left;
        line-height: 36px;
        margin-bottom: 0;
        margin-top: 0;
        margin-right: 10px;
        word-wrap: break-word;

        .remove-button {
          @extend %icon;
          @include vf-icon-close($color-mid-dark);
          border-bottom: 0;
        }
      }
    }

    .input {
      width: 100% !important;
    }
  }

  .tag-item {
    display: inline-block;
    background-color: #f7f7f7;
    padding: 0 5px;

    // Used to prevent removal of default commissioning scripts
    &.default-script {
      cursor: not-allowed;
      opacity: 0.5;

      .remove-button {
        display: none !important;
      }
    }
  }
}
