@mixin maas-divided-lists {
  .p-list--divided {
    .p-list__item {
      &::after {
        border-bottom-style: solid;
        border-bottom-color: $color-light-new;
      }
    }
  }
}
