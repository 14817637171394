@mixin maas-switch {
  .maas-p-switch {
    height: $sp-large;
    margin: $sp-small auto 0;
    position: relative;
    width: $sp-large * 2;
    display: inline-block;

    &--input {
      cursor: pointer;
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &--mask {
      background: $color-light;
      height: 100%;
      line-height: $sp-large;
      margin-top: 0;
      pointer-events: none;
      position: relative;

      &::before {
        @include vf-animation(background-color, brisk);
        background: $color-mid-light;
        content: "";
        display: block;
        height: 100%;
        text-align: right;
        width: 100%;
        padding: 0 0.3rem 0 0.45rem;
        box-shadow: inset 0 2px 5px 0 transparentize($color-dark, 0.8);
        border-radius: 3px;
        font-size: 0.875rem;
      }

      &::after {
        @include vf-animation(left, brisk, out);
        width: 50%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: $color-x-light;
        content: " ";
        box-shadow: 0 1px 5px 1px transparentize($color-dark, 0.8);
        border-radius: 3px;
      }
    }

    // Checked state
    &--input:checked + &--mask {
      &::before {
        text-align: left;
        content: "";
        color: $color-x-light;
        background: $color-information;
      }

      &::after {
        left: 50%;
      }
    }

    // Disabled state
    &--input:disabled {
      cursor: not-allowed;
    }

    &--input:disabled + &--mask {
      &::before {
        background: lighten($color-mid-light, 15%);
        color: lighten($color-x-dark, 60%);
      }
    }

    // Disabled and checked state
    &--input:disabled:checked + &--mask {
      &::before {
        background: lighten($color-information, 30%);
        color: $color-x-light;
      }
    }
  }
}
