@mixin maas-table-storage {
  .p-table--disks-partitions,
  .p-table--used-disks {
    .p-table__row {
      .p-table__cell {
        flex: 0 0 auto !important;

        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 7%;
        }

        &:nth-child(4) {
          width: 9%;
        }

        &:nth-child(5) {
          width: 14%;
        }

        &:nth-child(6) {
          width: 30%;
        }

        &:nth-child(7) {
          width: 10%;
        }
      }
    }
  }

  .p-table--used-disks {
    .p-table__row {
      .p-table__cell {
        &:nth-child(6) {
          width: 15%;
        }

        &:nth-child(7) {
          width: 25%;
        }
      }
    }
  }

  .p-table--datastores {
    flex: 0 0 auto !important;

    .p-table__row {
      .p-table__cell {
        flex: 0 0 auto !important;

        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(2) {
          width: 22%;
        }

        &:nth-child(3) {
          width: 9%;
        }

        &:nth-child(4) {
          width: 44%;
        }

        &:nth-child(5) {
          width: 10%;
        }
      }
    }
  }

  .p-table--create-raid {
    &__name {
      @media (max-width: $breakpoint-medium) {
        width: 50%;
      }
      @media (min-width: $breakpoint-medium) {
        width: 30%;
      }
    }
    &__size {
      width: 10%;
    }
    &__type {
      width: 20%;
    }
    &__active {
      width: 10%;
    }
    &__spare {
      width: 10%;
    }
  }

  .p-table--create-volume-group {
    &__name {
      @media (max-width: $breakpoint-medium) {
        width: 50%;
      }
      @media (min-width: $breakpoint-medium) {
        width: 30%;
      }
    }
    &__size {
      width: 30%;
    }
    &__type {
      width: 20%;
    }
    &__empty {
      width: 10%;
    }
  }

  .p-table--bcache {
    &__name {
      @media (max-width: $breakpoint-medium) {
        width: 50%;
      }
      @media (min-width: $breakpoint-medium) {
        width: 30%;
      }
    }
    &__size {
      width: 30%;
    }
    &__type {
      width: 20%;
    }
    &__empty {
      width: 10%;
    }
  }

  .p-table--filesystems .p-table__row {
    .p-table__cell--name {
      width: 25%;
    }

    .p-table__cell--size {
      width: 15%;
    }

    .p-table__cell--filesystem {
      width: 15%;
    }

    .p-table__cell--mount-point {
      width: 15%;
    }

    .p-table__cell--mount-options {
      width: 15%;
    }

    .p-table__cell--actions {
      width: 15%;
    }
  }
}
