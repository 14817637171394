@mixin maas-table-ipranges {
  .p-table--ipranges {
    th:nth-child(1),
    td:nth-child(1) {
      width: 25%;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 25%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 13%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 13%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 12%;
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 12%;
    }

    .p-table-expanding__panel {
      width: 100%;
    }
  }
}
