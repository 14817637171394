@mixin maas-p-forms {
  @include maas-p-form-stacked;
  @include maas-p-form-inline;

  .p-form__group {
    @extend %vf-clearfix;
  }

  .form__group-input {
    input.in-warning {
      border-color: $color-caution !important;
      padding-right: $sp-x-large;
    }
  }

  .p-form__label {
    color: $color-dark;

    &.is-disabled {
      color: $color-mid-dark;
    }
  }

  maas-obj-form[disabled="disabled"] {
    .p-form__label {
      color: $color-mid-dark;
    }
  }

  .p-form-help-text {
    margin-top: -$spv-inner--small;
  }
}

@mixin maas-p-form-stacked {
  .p-form--stacked {
    .p-form__control {
      & > .p-control-text {
        @extend %default-text;
        display: block;
      }

      &--placeholder {
        display: block;
        margin-bottom: $spv-outer--scaleable - $spv-nudge * 2 + $sp-unit;
        min-height: calc(
          #{map-get($line-heights, default-text) + $spv-nudge * 2}
        );
        padding-bottom: calc(#{$spv-nudge} - 1px);
        padding-top: calc(#{$spv-nudge} - 1px);
      }
    }
  }
}

@mixin maas-p-form-inline {
  .p-form--inline .p-form__group .p-form__label {
    flex-shrink: 1;
  }

  .p-form--inline,
  .p-form--inline .p-form__group {
    width: 100%;
  }
}
