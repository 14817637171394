@mixin maas-p-sticky-footer {
  #maas-legacy {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;

    .p-footer {
      grid-row-end: span 1;
      grid-row-start: auto;
    }
  }
}
