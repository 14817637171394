@mixin maas-strip {
  padding: $sp-medium 0;

  @media only screen and (min-width: $breakpoint-large) {
    padding: $sp-large 0;
  }
}

@mixin maas-p-strip {
  .p-strip {
    @include maas-strip;

    &--light {
      @include maas-strip;
      background-color: $color-x-light;
    }

    &--dark {
      @include maas-strip;
    }
  }

  .p-strip,
  .p-strip--light,
  .p-strip--dark {
    &.is-shallow {
      padding: $sp-small 0;

      @media only screen and (min-width: $breakpoint-large) {
        padding: $sp-medium 0;
      }
    }
  }
}
