@mixin maas-page-header {
  .page-header {
    padding: 1.5rem 0;
    @extend %vf-has-box-shadow;
    position: sticky;
    top: 0;
    z-index: 5;

    &__title {
      @include vf-heading-4;
      display: inline-block;
      margin-bottom: 0.95rem;
    }

    &__status {
      color: $color-mid-dark;
      display: inline;
      margin-left: 1rem;
      position: relative;
      width: auto;

      // XXX header icon spacing fix
      // .u-vertically-center can't be used here
      // Issue: https://github.com/vanilla-framework/vanilla-framework/issues/1598
      [class^="p-icon"] {
        margin-bottom: 0.1rem;
      }
    }

    &__controls {
      float: right;

      @media (max-width: $breakpoint-small) {
        float: none;
      }
    }

    &.is-not-sticky {
      position: absolute;
    }
  }

  .page-header__controls--discoveries {
    button:last-child {
      margin-left: 1.5rem;
      position: relative;
      top: -0.05rem;
    }

    .maas-p-switch {
      position: relative;
      top: 0.14rem;
      margin-top: 0;
    }

    .p-form__label {
      margin-bottom: 0;
      padding-top: 0;
    }
  }

  .page-header__controls--images {
    .p-form__label {
      position: relative;
      top: -0.45rem;
    }
  }
}
