$grid-max-width: 1440 / 16 * 1rem; // express in rems for easier calculations

$color-navigation-background: #333;
$color-navigation-background--hover: darken($color-navigation-background, 3%);

$color-brand: #e95420;
$color-light-new: #e5e5e5;
$color-navigation-active-bar: $color-brand;

$breakpoint-x-large: 1300px;
$breakpoint-navigation-threshold: 870px;
$increase-font-size-on-larger-screens: false;

// Relative to the built css dir.
$assets-path: "../fonts/";

$multi: 1;
